.flexbox-panel {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 5px;
    height: 100vh;
}

.flexbox-item {
    flex: 0 1 1%;
}

.button {
    height: 50px;
    width: 150px;
    background-color: #0078d4;
    color: #323130;
    border: none;
}

.button:hover {
    cursor: pointer;
    background-color: #0058d4;
}
.error {
    color: red;
    font-weight: bold;
}